
import Vue from 'vue';
import {StoryblokLink} from '@/helpers/storyblok/storyblok';
import BaseIcon from '~/components/BaseIcon.vue';

export default Vue.extend({
  name: 'SocialMediaElementIcon',
  components: {
    BaseIcon,
  },
  props: {
    icon: String,
    link: Object as () => StoryblokLink,
  },
});
